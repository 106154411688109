:root {
    --zs-white: #FFFFFF;
  
    --zs-gray-50: #F7F7F7;
    --zs-gray-100: #EEEEEE;
    --zs-gray-200: #d6d6d6;
    --zs-gray-400: #b0b0b0;
    --zs-gray-700: #2f2f2f;
    --zs-gray-750: #000;
    --zs-gray-750-hover: #404040c5;
    --zs-gray-750-opacity: #40404038;
    --zs-gray-750-disabled: #C0C0C040;
    --zs-gray-750-selected: #C0C0C0;
  
    --text-input: #333333;
  
    --zs-primary-500: #4D4D4D;
    --zs-primary-500-hover: #4D4D4Dc5;
    --zs-primary-500-disabled: #4D4D4D40;
    --zs-primary-600: #3A3A3A;
    --zs-primary-700: #292929;
    --zs-orange-700: #F77754;
  
    --zs-background-square: url("../public/assets/img/background.jpg");
    --zs-background: #202020;
    --zs-background-text-color: var(--zs-white);
  }